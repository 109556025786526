<template>
   <div class="max-w-screen-mobile mx-auto h-screen py-2">
     <Menu />
   </div>
</template>

<script>
import Menu from "@/components/mobile/Menu.vue"

export default {
  name: 'MobileMenu',
  components: {
    Menu
  }
}
</script>
