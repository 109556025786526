<template>
  <div class="text-center relative h-screen overflow-auto">
    <img v-bind:src="getLogo()" alt="Logo" class="w-24 mx-auto my-6">
    <h4 class="font-heading text-h4 text-sepiaBlack dark:text-white">{{ $t('menu.message', { input: name }) }}</h4>
    <PinCode class="mb-6 mt-2"/>
    <ul class="mx-6">
      <ul class="rounded-lg">
        <li>
          <router-link :to="{ name: 'PasswordReset' }">
            <MenuItem number=01 :text="$t('menu.settings')" class="rounded-t-lg"/>
          </router-link> 
        </li>
      </ul>
      <li class="my-4">
        <router-link :to="{ name: 'LanguageMobile' }">
          <MenuItem number=02 :text="$t('menu.lang')" class="rounded-lg"/>
        </router-link> 
      </li>
      <li>
        <router-link :to="{ name: 'Onboarding1' }">
          <MenuItem number=03 :text="$t('menu.onboarding')" class="rounded-lg"/>
        </router-link>
      </li>
    </ul>
    <!-- <DarkMode v-on:update_class="toggleDark" /> -->
    <MobileNavigation  class="bottom-2.5"/>
  </div>
</template>

<script>
import PinCode from "@/components/PinCode.vue";
import MobileNavigation from "@/components/mobile/Navigation.vue";
// import DarkMode from '@/components/DarkMode.vue';
import MenuItem from "@/components/mobile/MenuItem.vue"

export default {
  name: 'Menu',
  data() {
    return {
      name: window.localStorage.name,
      lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
    }
  },
  components: {
    PinCode,
    MobileNavigation,
    // DarkMode
    MenuItem
  },
  mounted() {
    this.$i18n.locale = this.lang;
  },
  methods: {
    // toggleDark: function() {
    //   document.querySelector("html").classList.toggle("dark");
    //   document.querySelector("#app").classList.toggle("darkMode");
    // },
    getLogo: function() {
      const element = document.getElementById("app");
      if (element.classList.contains('darkMode')) {
        return require("../../assets/logo_dark.png");
      } else {
        return require("../../assets/logo_light.png");
      }
    },
  },
  computed: {
    src() {
      if (window.localStorage.theme == 'light') {
        return require("../../assets/Logo-dark.png");
      } else {
        return require("../../assets/Logo-light.png")
      }
    }
  }
}
</script>
